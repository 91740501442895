module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BO MIRHOSSENNI","short_name":"BO MIRHOSSENI","icon":"/opt/build/repo/src/images/favicon-32x32.png","start_url":"/","background_color":"#000000","theme_color":"#FFFFFF","display":"standalone","description":"The portfolio of Bo Mirhosseni","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cd5ab52e38d1e2c1d28e634ef697fcf8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-5BDGC43X69"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
